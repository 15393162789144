import { BubbleMenu, EditorContent, useEditor } from "@tiptap/react"
import StarterKit from "@tiptap/starter-kit"

export const EditMe = () => {
  const editor = useEditor({
    extensions: [StarterKit],
    editorProps: {
      attributes: {
        class:
          "mt-3 text-pretty px-4 text-center font-sans text-lg font-medium !leading-normal text-gray-800 md:mx-auto md:max-w-4xl md:text-3xl md:text-left",
      },
    },
    content: `
      <p>
        How much time does your team spend on updating content because it's too difficult? <em>Click here to edit this paragraph. Seriously. </em>|
      </p>
    `,
  })

  return (
    <>
      {editor && (
        <BubbleMenu
          className="focus: flex gap-x-2 rounded-md border border-gray-400 bg-white px-2 py-3"
          tippyOptions={{ duration: 100 }}
          editor={editor}
        >
          <button
            onClick={() => editor.chain().focus().toggleBold().run()}
            className={editor.isActive("bold") ? "is-active" : ""}
          >
            Bold
          </button>
          <button
            onClick={() => editor.chain().focus().toggleItalic().run()}
            className={editor.isActive("italic") ? "is-active" : ""}
          >
            Italic
          </button>
          <button
            onClick={() => editor.chain().focus().toggleStrike().run()}
            className={editor.isActive("strike") ? "is-active" : ""}
          >
            Strike
          </button>
        </BubbleMenu>
      )}

      <EditorContent editor={editor} />
    </>
  )
}
