import clsx from "clsx"
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "~/components/ui/carousel"
import Autoplay from "embla-carousel-autoplay"

const featuredTestimonial = {
  body: "I've turned to Soung Rong since 2018 to troubleshoot a unique tech issue for 15 years of data storage, which he pro-actively solved at a reasonable cost. Teamed with his warm demeanour, creativity in problem-solving and efficient responses; he's been my go-to for all programming and tech queries or projects ever since.",
  author: {
    name: "Joyce Wong",
    handle: "Creative Strategist",
    imageUrl: "/logos/kbf-color-logo.png",
  },
}
const testimonials = [
  [
    [
      {
        body: "We came to Goodwerk with some crazy ideas for our new app. They took our bombastic vision and turned it into a user-friendly, functional masterpiece. The team was amazing - super responsive and always kept things clear.",
        author: {
          name: "Michele Mattotti",
          handle: "Former Head of E-commerce",
          imageUrl: "/logos/heineken-color-logo.png",
        },
      },
    ],
    [
      {
        body: "Their services provided meets our requirements and there hasn’t been any disruptions to our website under their watch. Exactly the kind of partners we need!",
        author: {
          name: "Eric Tan",
          handle: "Senior IT Executive",
          imageUrl: "/logos/eversafe-color-logo.png",
        },
      },
    ],
  ],
  [
    [
      {
        body: "The GoodWerk team displayed a positive, never-say-die attitude throughout our website build. Looking forward to our next project together!",
        author: {
          name: "Lim Feixiang",
          handle: "Group Sales & Business Development Director",
          imageUrl: "/logos/yolo-color-logo.png",
        },
      },
    ],
    [
      {
        body: "Working with Soung Rong has been an absolute pleasure. Soung is not only incredibly knowledgeable and skilled in his field, but he also has a rare talent for explaining complex concepts in the simplest and most understandable ways. His honesty and always-ready-to-serve attitude make him a trustworthy partner. Soung's dedication to providing top-notch service and his willingness to go the extra mile have been instrumental in enhancing our online presence. I highly recommend Soung Rong for his exceptional expertise and unwavering commitment to his clients.",
        author: {
          name: "Gem Yen",
          handle: "Head of Marketing",
          imageUrl: "/logos/book-xcess-color-logo.png",
        },
      },
    ],
  ],
]

export const Testimonials = () => {
  return (
    <div
      id="testimonials"
      className="relative isolate bg-off-white pb-10 md:pb-20"
    >
      <h2 className="header-style mt-2">{`Testimonials`}</h2>
      <p className="text-black-400 mb-2 mt-3 text-pretty px-4 text-center font-sans text-lg font-medium !leading-normal md:mx-auto md:max-w-4xl md:text-3xl">{`Here's what those who have worked with us have to say:`}</p>
      <div
        className="absolute inset-x-0 top-1/2 -z-10 -translate-y-1/2 transform-gpu overflow-hidden opacity-30 blur-3xl"
        aria-hidden="true"
      >
        <div
          className="ml-[max(50%,38rem)] aspect-[1313/771] w-[82.0625rem] bg-gradient-to-tr from-off-yellow to-amber-400"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <div
        className="absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden pt-32 opacity-25 blur-3xl sm:pt-40 xl:justify-end"
        aria-hidden="true"
      >
        <div
          className="ml-[-22rem] aspect-[1313/771] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] bg-gradient-to-tr from-off-yellow to-amber-400 xl:ml-0 xl:mr-[calc(50%-12rem)]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <Carousel
        className="block p-6 sm:hidden"
        opts={{
          loop: true,
        }}
        plugins={[Autoplay({ playOnInit: true, delay: 3000 })]}
      >
        <CarouselContent>
          {[...testimonials, featuredTestimonial]
            .flat()
            .flat()
            .map((testimonial, index) => (
              <CarouselItem key={index} className="basis-full">
                <figure
                  key={testimonial.author.handle}
                  className="z-20 rounded-2xl border border-gray-400 bg-white p-6 drop-shadow-md"
                >
                  <blockquote className="text-gray-900">
                    <p>{`“${testimonial.body}”`}</p>
                  </blockquote>
                  <figcaption className="mt-6 flex items-center justify-between gap-x-4">
                    <div>
                      <div className="font-semibold">
                        {testimonial.author.name}
                      </div>
                      <div className="text-xs !leading-relaxed text-gray-600">{`${testimonial.author.handle}`}</div>
                    </div>
                    <img
                      className="h-12 w-auto flex-none"
                      src={testimonial.author.imageUrl}
                      alt=""
                    />
                  </figcaption>
                </figure>
              </CarouselItem>
            ))}
        </CarouselContent>
      </Carousel>

      <div className="mx-auto hidden max-w-7xl px-6 sm:block lg:px-8">
        <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 text-sm leading-6 text-gray-900 sm:mt-10 sm:grid-cols-2 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4">
          <figure className="rounded-2xl border border-gray-400 bg-white drop-shadow-md sm:col-span-2 xl:col-start-2 xl:row-end-1">
            <blockquote className="p-6 text-lg font-semibold leading-7 tracking-tight text-gray-900 sm:p-12 sm:text-xl sm:leading-8">
              <p>{`“${featuredTestimonial.body}”`}</p>
            </blockquote>
            <figcaption className="flex flex-wrap items-center gap-x-4 gap-y-4 border-t border-gray-900/10 px-6 py-4 sm:flex-nowrap">
              <div className="flex-auto">
                <div className="font-semibold">
                  {featuredTestimonial.author.name}
                </div>
                <div className="text-xs text-gray-600">{`${featuredTestimonial.author.handle}`}</div>
              </div>
              <img
                className="h-10 w-auto flex-none"
                src={featuredTestimonial.author.imageUrl}
                alt=""
              />
            </figcaption>
          </figure>
          {testimonials.map((columnGroup, columnGroupIdx) => (
            <div
              key={columnGroupIdx}
              className="space-y-8 xl:contents xl:space-y-0"
            >
              {columnGroup.map((column, columnIdx) => (
                <div
                  key={columnIdx}
                  className={clsx(
                    (columnGroupIdx === 0 && columnIdx === 0) ||
                      (columnGroupIdx === testimonials.length - 1 &&
                        columnIdx === columnGroup.length - 1)
                      ? "xl:row-span-2"
                      : "xl:row-start-1",
                    "space-y-8",
                  )}
                >
                  {column.map((testimonial) => (
                    <figure
                      key={testimonial.author.handle}
                      className="rounded-2xl border border-gray-400 bg-white p-6 drop-shadow-md"
                    >
                      <blockquote className="text-gray-900">
                        <p>{`“${testimonial.body}”`}</p>
                      </blockquote>
                      <figcaption className="mt-6 flex items-center justify-between gap-x-4">
                        <div>
                          <div className="font-semibold">
                            {testimonial.author.name}
                          </div>
                          <div className="text-xs !leading-relaxed text-gray-600">{`${testimonial.author.handle}`}</div>
                        </div>
                        <img
                          className="h-10 w-auto flex-none"
                          src={testimonial.author.imageUrl}
                          alt=""
                        />
                      </figcaption>
                    </figure>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
