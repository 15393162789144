import { useMotionValue, Reorder } from "framer-motion"
import { ClassNameValue } from "tailwind-merge"
import { useRaisedShadow } from "~/components/raised-shadow"
import { DragHandleDots1Icon } from "@radix-ui/react-icons"
import clsx from "clsx"

interface Props {
  item: string
  className: ClassNameValue
}

export const Item = ({ item, className }: Props) => {
  const y = useMotionValue(0)
  const boxShadow = useRaisedShadow(y)

  return (
    <Reorder.Item
      value={item}
      id={item}
      style={{ boxShadow, y }}
      className={clsx(
        className,
        "flex cursor-pointer justify-between rounded-md border-2 border-gray-300 px-2 py-2 text-lg",
      )}
    >
      <span>{item}</span>
      <DragHandleDots1Icon className="size-6" />
    </Reorder.Item>
  )
}
