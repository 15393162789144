import clsx from "clsx"
import { useState, useRef, useEffect, useMemo } from "react"
import { Reorder } from "framer-motion"
import { Item } from "~/components/reorder-item"
import {
  IndustriesSlider,
  TechnologiesSlider,
  ClientLogoSlider,
} from "~/components/sliders"
import { EditMe } from "~/components/edit-me"
import { Testimonials } from "~/components/testimonials"
import random from "just-random-integer"
import debounce from "just-debounce-it"
import { JankSpinner } from "~/components/features-device"

export const links = () => {
  return [
    {
      rel: "canonical",
      href: "https://goodwerk.io",
    },
  ]
}

export const meta = () => {
  const TITLE = "GOODWERK | Practical Software Development & Solutions"
  const DESCRIPTION =
    "We consult and build practical websites and solutions for business needs of any size."
  const IMAGE_PATH = "/assets/goodwerk-makes-effective-websites.png"

  return [
    { title: TITLE },
    {
      name: "description",
      content: DESCRIPTION,
    },
    { property: "og:title", content: TITLE },
    { property: "og:description", content: DESCRIPTION },
    { property: "og:image", content: IMAGE_PATH },
    { name: "twitter:title", content: TITLE },
    { name: "twitter:description", content: DESCRIPTION },
    { name: "twitter:image", content: IMAGE_PATH },
  ]
}

export const JumboP = ({
  children,
  className,
  ...props
}: React.ComponentPropsWithoutRef<"p">) => {
  return (
    <div
      {...props}
      className={clsx(
        "text-pretty px-4 text-center font-sans text-4xl font-black !leading-tight text-black md:mx-auto md:max-w-4xl md:text-6xl",
        className,
      )}
    >
      {children}
    </div>
  )
}

export const SubHeadline = ({
  children,
  className,
  ...props
}: React.ComponentPropsWithoutRef<"div">) => {
  return (
    <div
      {...props}
      className={clsx(
        "mt-3 text-pretty px-4 text-center font-sans text-lg font-medium !leading-normal text-gray-800 md:mx-auto md:max-w-4xl md:text-3xl",
        className,
      )}
    >
      {children}
    </div>
  )
}

export default function Index() {
  return (
    <div className="bg-off-white">
      <div className="flex h-svh flex-col items-center">
        <div className="flex flex-auto flex-col items-center justify-center">
          <div className="max-w-4xl px-4 sm:px-0">
            <h1 className="text-center font-sans text-4xl font-black !leading-tight text-black sm:text-6xl">
              <span className="skewed-highlight-down">Stop</span>
              {`spending money on websites that don't work.`}
            </h1>
            <p className="mt-4 text-pretty text-center font-sans text-lg font-medium !leading-normal text-gray-800 sm:text-3xl">
              Your website should be{" "}
              <a href="#fast" className="link-highlight sm:text-3xl">
                fast 🚀
              </a>
              ,{" "}
              <a href="#effective" className="link-highlight sm:text-3xl">
                effective at selling ⭐️
              </a>
              , and{` `}
              <a href="#maintainable" className="link-highlight sm:text-3xl">
                easy to maintain 🫖
              </a>
              .
            </p>
          </div>
          <div className="mt-24 flex flex-col gap-y-2 sm:flex-row">
            <TechnologiesSlider />
          </div>
        </div>

        <div className="mb-20 flex flex-col items-center">
          <span className="animate-bounce text-3xl">⬇️</span>
          <p className="font-sans text-lg font-semibold lowercase text-black">
            Scroll Down
          </p>
        </div>
      </div>

      <div
        id="what-we-do"
        className="my-20 flex flex-col items-center md:pb-24 md:pt-16"
      >
        <JumboP className="">
          {`If it's`}
          <span className="highlight">{`not`}</span>
          {`broken, we won't 🙅🏻‍♀️ fix it.`}
        </JumboP>
        <SubHeadline className="">
          {`We consult and build practical websites and solutions for business needs of any size.`}
        </SubHeadline>
        <a href="/contact" className="link-highlight mt-4">
          Ask Us Anything <span className="text-3xl">🙋🏻‍♂️</span>
        </a>
      </div>
      <div
        id="fast"
        className="mx-auto my-20 flex max-w-6xl flex-col-reverse items-center md:flex-row"
      >
        <div className="flex flex-col items-center pt-4 md:items-start md:pr-5">
          <JumboP className="md:text-left">
            Slow 🐢 websites <span className="skewed-highlight-down">lose</span>{" "}
            customers.
          </JumboP>
          <SubHeadline className="md:text-left">{`Mobile phones and computers are getting faster, but why is your website slower? Faster websites get more traffic and retain more customers.`}</SubHeadline>
          <a href="/contact" className="link-highlight ml-4 mt-4">
            Get a faster website in 24 hours ➡️
          </a>
        </div>
        <div className="size-20 md:size-32">
          <JankSpinner />
        </div>
      </div>

      <div
        id="effective"
        className="mx-auto my-20 flex max-w-6xl flex-col items-center sm:my-36"
      >
        <div className="mx-auto w-full max-w-2xl rounded-t-lg px-6 drop-shadow-md">
          <div className="flex w-full items-center justify-start space-x-1.5 rounded-t-lg bg-gray-200 px-3 py-2">
            <span className="h-3 w-3 rounded-full bg-red-400"></span>
            <span className="h-3 w-3 rounded-full bg-yellow-400"></span>
            <span className="h-3 w-3 rounded-full bg-green-400"></span>
          </div>
          <img
            className="w-full border border-t-0 border-gray-200 sm:max-w-3xl"
            src="/assets/add-to-cart.gif"
            alt="Checkout Flow"
          />
        </div>

        <div className="flex flex-col items-center pt-4 md:pt-10">
          <JumboP className="">
            <span className="skewed-highlight-down">{`Don't`}</span>
            {`make your customers think 🤔.`}
          </JumboP>
          <SubHeadline className="">{`Unless you're running an art gallery, your website needs to be persuasive and functional. Nothing more, nothing less.`}</SubHeadline>
          <a href="/contact" className="link-highlight ml-4 mt-4">
            Make my website work better ➡️
          </a>
        </div>
      </div>

      <div
        id="maintainable"
        className="mx-auto my-20 flex max-w-6xl flex-col-reverse items-center sm:my-36 md:flex-row"
      >
        <div className="flex flex-col items-center md:items-start md:pr-20">
          <JumboP className="md:text-left">
            Keeping your website updated should be{" "}
            <span className="highlight">easy</span>.
          </JumboP>
          <EditMe />
          <a href="/contact" className="link-highlight ml-4 mt-4">
            {`Increase my team's productivity ➡️`}
          </a>
        </div>
      </div>

      <Quiz />
      <Testimonials />
      <ClientLogoSlider />

      <div className="flex flex-col items-center py-10">
        <JumboP className="text-center">
          {`You don't notice good design, because it blends into how we understand
          the world.`}
        </JumboP>
        <a href="/contact" className="link-highlight mt-4">
          Get a free consultation ➡️
        </a>
      </div>

      <div className="flex flex-col gap-y-2 py-10">
        <IndustriesSlider />
      </div>
    </div>
  )
}

const useDebounce = (callback) => {
  const ref = useRef()

  useEffect(() => {
    ref.current = callback
  }, [callback])

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.()
    }

    return debounce(func, 200)
  }, [])

  return debouncedCallback
}

const Quiz = () => {
  const initialItems = [
    "🙋🏻‍♀️ Engaging",
    "🍴 Intuitive",
    "👀 Informative",
    "🏃🏻‍♂️ Compelling",
  ]
  const [items, setItems] = useState(initialItems)
  const hintOptions = [
    "Are you sure that's correct?",
    "You might be right.",
    "They seem equally important don't they?",
    "Is that your final answer?",
    "Think carefully.",
  ]
  const [showHint, setShowHint] = useState(null)
  const [showAnswer, setShowAnswer] = useState(false)

  const debouncedRequest = useDebounce(() => {
    setShowHint(hintOptions[random(0, hintOptions.length - 1)])
  })

  return (
    <div>
      <JumboP>
        {`What are the most`}
        <span className="highlight">important</span>
        {`qualities for a website?`}
      </JumboP>
      <SubHeadline>
        Drag the items below, and place the most important element on top.
      </SubHeadline>
      <div className="mx-auto flex flex-col items-center pb-5 pt-10 md:max-w-4xl">
        <Reorder.Group
          axis="y"
          onReorder={(newOrder) => {
            setItems(newOrder)
            debouncedRequest()
          }}
          values={items}
          className="flex w-64 flex-col gap-y-3"
        >
          {items.map((item) => (
            <Item key={item} item={item} className="bg-white">
              {item}
            </Item>
          ))}
        </Reorder.Group>
        <button
          type="button"
          className={clsx(
            showHint ? "visible" : "invisible",
            showAnswer ? "bg-green-200" : "text-black-800 bg-amber-400",
            "relative z-10 mt-6 flex w-64 cursor-pointer items-center justify-between rounded-md border-none px-3 py-2",
          )}
          onClick={() => setShowAnswer(true)}
          disabled={!showHint}
        >
          {showAnswer ? (
            <>
              <span>{`You're right!`}</span>
              <span className="text-xl">✅</span>
            </>
          ) : (
            <>
              <span className="font-medium">Check my answer</span>
              <span className="text-xl">➡️</span>
              <span className="absolute -right-2 -top-2 flex size-4">
                <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-custom-orange opacity-75"></span>
                <span className="relative inline-flex size-4 rounded-full bg-custom-orange"></span>
              </span>
            </>
          )}
        </button>
        {showAnswer ? (
          <p className="my-6 px-4 text-center font-medium text-gray-700">{`All answers are correct, as long as it helps you achieve your business goals.`}</p>
        ) : (
          <p
            className={clsx(
              showHint ? "visible" : "invisible",
              "my-6 px-4 text-center font-medium text-gray-700",
            )}
          >
            {showHint ? showHint : "Hmm..."}
          </p>
        )}
      </div>
    </div>
  )
}
